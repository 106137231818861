<template>
  <div class="nomination-completed" v-loading="loading">
      <div class="items" v-if="nominations.length > 0">
        <div class="item" v-for="(item, index) in nominations" :key="index" style="">
          <div>
            <div class="item-number">{{ item.numberCompleted }}</div>
            <div class="item-label">{{ item.companyName }}</div> 
          </div>
          <!-- <div v-if="index < nominations.length - 1" style="width: 1px; height: 62px; background-color: #A9AEB9; margin: 0 10px;"></div> -->
        </div>
      </div>
      <div v-else>
        No data found
      </div>
    </div>
</template>
<script>
import { getDashboardNominationCompleted } from "@/services/form";
export default {
  name: "NominationCompletedTab",
  props: {
    startDate: {
      type: String
    },
    endDate: {
      type: String
    },
    mock: {
      type: Boolean,
      default: false,
    }
  },
  async created() {
    await this.getData()
  },
  data() {
    return {
      loading: true,
      total: 0,
      barge: 0,
      vessel: 0,
      others: 0,
      nominations:[],
    }
  },
  methods: {
    async getData() {
      this.loading = true
      const param = {
        "startDate": this.startDate, 
        "endDate": this.endDate,
      }
      const res = await getDashboardNominationCompleted(param)
      this.nominations = res?.data? res.data : []
      this.loading = false
    }
  }
}
</script>
<style scoped>
.nomination-completed {
  width: 100%;
  padding: 5px;
  overflow-x: auto;
  .items {
    display: flex;
    width: 100%;

    .item {
      display: flex; 
      /* align-items: center; */
      flex: 0 0 auto;
      text-align: center;
      border-right: 1px solid #A9AEB9;
      /* padding: 0px 10px 0px 15px; */
      /* margin: 0px 10px; */
      width: 130px;
      /* height: 62px; */
      justify-content: center;

      .item-number{
        /* font-size: 1.5em; */
        /* font-weight: bold; */
        /* color: #435AD3; */
        text-align: center;
        /* font-family: Roboto; */
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .item-label{
        font-size: 1em;
        color: #555;
        word-break: break-word;
        white-space: normal;
        /* color: #435AD3; */
        text-align: center;
        /* font-family: Roboto; */
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .item:last-child {
      border-right: none;
    }
  }
}
</style>