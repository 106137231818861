<template>
  <div class="h-full">
    <customer-create-list v-if="showPage === 0" />
    <div v-else-if="showPage === 1">
      <DashboardCharts />
      <terminal-ops ref="terminalOps" />
    </div>
    <order-list v-else-if="showPage === 2" />
  </div>
</template>

<script>
import DashboardCharts from "./charts/dashboard.vue";
import customerCreateList from "./components/customer-create-list.vue";
import TerminalOps from "@/components/common/terminal-ops.vue";
import OrderList from "@/view/barging/components/order-list.vue";
export default {
  components: { customerCreateList, TerminalOps, OrderList, DashboardCharts },
  name: "dashboard",
  data() {
    return {
      showPage: 1,
    };
  },
  created() {
    if (this.$store.state.userInfo.userType == 4) {
      this.showPage = 0;
    }
    if (this.$store.state.userInfo.userType == 2) {
      this.showPage = 0;
    }
    if (this.$store.state.userInfo.userType == 3) {
      this.showPage = 2;
    }
  },
  beforeRouteLeave (to, from, next) {
    if (this.$refs.terminalOps) {
      this.$refs.terminalOps.handleStopRefresh()
    }
    next()
  },
};
</script>

<style lang="scss" scoped></style>
